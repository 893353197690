import React from 'react';

const ChatInput = ({ inputRef, value, handleKeyDown, handleChange }) => {
  return (
    <textarea
      ref={inputRef}
      className="chatview__textarea-message"
      rows={1}
      value={value}
      onKeyDown={handleKeyDown}
      onChange={handleChange}
      placeholder="Type your message here..."
    />
  );
};

export default ChatInput;
