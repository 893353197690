import React from 'react';

const FileUpload = ({ setBase64File, setModalFileOpen, fileInputRef }) => {
  const handleFileUpload = (event) => {
    const file = event.target.files[0];

    // Check if the file is an image.
    if (!file.type.startsWith('image/')) {
      console.error('File is not an image.');
      return;
    }

    // Create a new FileReader.
    const reader = new FileReader();

    // Set the FileReader's onload function.
    reader.onload = function (event) {
      // The result is a base64-encoded data URL.
      const base64 = event.target.result;
      setBase64File(base64);
    };

    // Read the file as a data URL.
    reader.readAsDataURL(file);
  };

  return (
    <div className="container mx-auto flex">
      <input
        type="file"
        className="file-input file-input-bordered file-input-warning w-full"
        onChange={handleFileUpload}
        ref={fileInputRef}
      />
      <button className="btn btn-active mx-4 justify-end" onClick={() => setModalFileOpen(false)}>
        Close
      </button>
    </div>
  );
};

export default FileUpload;
