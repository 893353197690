import React from 'react';

const StatusIndicator = ({ connectionStatus }) => {
  return (
    <div className="indicator flex justify-center items-center w-full">
      <span
        className={`indicator-start text-white badge ${
          connectionStatus === 'Connected' ? 'badge-success' : 'badge-error'
        }`}
      >
        {' '}
        {connectionStatus}
      </span>
    </div>
  );
};

export default StatusIndicator;
